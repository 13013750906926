<template>
	<div class="tab-content">
		<table class="table table-striped brand draft-list">
			<thead>
				<tr>
					<th class="col-2"></th>
					<th class="col-3">Course Title</th>
					<th class="col-2 text-center">Date Created</th>
					<th class="col-6"></th>
				</tr>
			</thead>
			<tbody>

				<RowData
					v-for="course in displayedCourses.slice(0, limit)"
					:key="course.id"
					:image="course.images"
					:name="course.title"
					:td1Class="`text-center`"
					:dateLeft="`${new Date(
						course.created_at.slice(0, 10)
					).toLocaleDateString()} <br><small>Last Edited: ${Math.ceil(
						Number(new Date() - new Date(course.updated_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago</small>`"
					:buttons="[
						{ title: 'Quiz', action: () => goEditQuiz(course.id) },
						{
							title: 'Analytics',
							action: () => showAnalytics(course.id)
						},
						{
							title: 'Delete',
							action: () => deleteCourse(course.id)
						},
						{
							title: 'Duplicate',
							action: () => duplicateCourse(course)
						},
						{
							title: 'Edit',
							action: () => editCourse(course.id)
						}
					]"
				/>

			</tbody>
		</table>

			<v-btn
			v-if="displayedCourses.length > limit"
			class="admin-primary-button secondary-contrast-background"
			:loading="isLoading"
			@click="loadMoreCourses"
			>Load More</v-btn
		>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-subheader
					>Published Courses</admin-default-subheader
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<RowData
					v-for="course in displayedCourses.slice(0, limit)"
					:key="course.id"
					:image="course.images"
					:name="course.title"
					:dateLeft="`Last Edited: ${Math.ceil(
						Number(new Date() - new Date(course.updated_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago`"
					:buttons="[
						{ title: 'Quiz', action: () => goEditQuiz(course.id) },
						{
							title: 'Analytics',
							action: () => showAnalytics(course.id)
						},
						{
							title: 'Delete',
							action: () => deleteCourse(course.id)
						},
						{
							title: 'Duplicate',
							action: () => duplicateCourse(course)
						},
						{
							title: 'Edit',
							action: () => editCourse(course.id)
						}
					]"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column align-center">
				<v-btn
					v-if="publishedCourses.length > limit"
					class="admin-primary-button secondary-contrast-background"
					@click="loadMoreCourses"
					>Load More</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import { mapGetters } from "vuex";
import { post } from "@/util/requests/post";
import { remove } from "@/util/requests/remove";
import { get } from "@/util/requests/get";
import { endpoints } from "@/util/widgets/endpoints.js";
export default {
	components: { AdminDefaultSubheader },
	name: "CoursesPublished",
	props: ["searchText"],
	computed: {
		...mapGetters(["publishedCourses"]),
		displayedCourses() {
			return this.publishedCourses.filter(el =>
				String(el.title).includes(this.searchText)
			);
		}
	},
	data() {
		return {
			limit: 2
		};
	},
	watch: {
		displayedCourses: function () {
			this.loadMoreCourses();
		}
	},
	methods: {
		loadMoreCourses() {
			this.limit = this.publishedCourses.length;
		},
		editCourse(id) {
			this.$router.push({
				name: "CourseEditor",
				params: { id: id }
			});
		},
		async goEditQuiz(id) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const {
					data: { data: course }
				} = await get(`/course/${id}`);
				console.log(course.quizzes[0].quiz_id);
				if (!!course.quizzes === false) return;
				this.$router.push({
					name: "QuizEditor",
					params: { id: course.quizzes[0].quiz_id }
				});
				await this.$store.dispatch("setIsLoading", false);
			} catch (error) {
				console.error(error);
				await this.$store.dispatch("setIsLoading", false);
			}
		},
		async deleteCourse(id) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await remove(`/course/${id}`);
				if (response.status === 200) {
					this.$store.dispatch("getCourses");
				}
			} catch (error) {
				this.$emit("setMessage", error.response.data.message);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async duplicateCourse(originalCourse) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const duplicatedCourse = await post("/course", {
					user_id: originalCourse.user_id,
					organization_id: this.organization.id,
					course_type_id: originalCourse.course_type_id,
					title: `${originalCourse.title} (copy)`,
					description: originalCourse.description,
					duration: originalCourse.duration,
					category: originalCourse.category,
					custom: originalCourse.custom,
					parent_course_id: originalCourse.parent_course_id,
					locked_by_parent: originalCourse.locked_by_parent,
					category_id: originalCourse.category_id
				});
				if (duplicatedCourse.status === 201) {
					const {
						data: { data: duplicateCourseData }
					} = duplicatedCourse;

					const originalCourseResponse = await get(
						`/course/${originalCourse.id}`
					);

					const {
						data: { data: originalCourseData }
					} = originalCourseResponse;
					originalCourseData.chapters.forEach(async chapter => {
						const chapterResponse = await post("/chapter", {
							course_id: duplicateCourseData.id,
							title: chapter.title,
							content: chapter.content,
							sort_order: chapter.sort_order,
							custom: chapter.custom,
							category_id: chapter.category_id
						});
						chapter.pages.forEach(async page => {
							const pageResponse = await post("/page", {
								chapter_id: chapterResponse.data.data.id,
								title: page.title,
								content: page.content,
								sort_order: page.sort_order
							});
							page.blocks.forEach(async el => {
								const widget = await post(
									`/${endpoints.widgets[el.blk_type_id]}`,
									{
										course_id: duplicateCourseData.id,
										chapter_id:
											chapterResponse.data.data.id,
										page_id: page.id,
										title: el.block.title,
										head_level: el.block.head_level,
										content: el.block.content,
										css_class: el.block.css_class,
										sort_order: el.block.sort_order
									}
								);

								await post("/page_block", {
									page_id: pageResponse.data.data.id,
									blk_type_id: el.blk_type_id,
									model_id: widget.data.data.id,
									sort_order: widget.data.data.sort_order
								});
							});
						});
					});
					await this.$store.dispatch("getCourses");
				}
			} catch (error) {
				this.$emit("setMessage", error.response.data.message);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		showAnalytics(id) {
			this.$router.push({
				name: "CourseAnalytics",
				params: { id: id }
			});
		}
	}
};
</script>

<style></style>
