<template>
	<div class="tab-content">
		<table class="table table-striped brand draft-list">
			<thead>
				<tr>
					<th class="col-2"></th>
					<th class="col-3">Course Title</th>
					<th class="col-2 text-center">Date Created</th>
					<th class="col-6"></th>
				</tr>
			</thead>
			<tbody>

				<RowData
					v-for="course in displayedCourses"
					:key="course.id"
					:image="course.images"
					:name="course.title"
					:td1Class="`text-center`"
					:dateLeft="`${new Date(
						course.created_at.slice(0, 10)
					).toLocaleDateString()} <br><small>Last Edited: ${Math.ceil(
						Number(new Date() - new Date(course.updated_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago</small>`"
				/>

			</tbody>
		</table>

		<v-btn
			v-if="displayedCourses.length > limit"
			class="admin-primary-button secondary-contrast-background"
			:loading="isLoading"
			@click="loadMoreCourses"
			>Load More</v-btn
		>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-subheader
					>Deleted Courses</admin-default-subheader
				>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<RowData
					v-for="course in displayedCourses"
					:key="course.id"
					:image="course.images"
					:name="course.title"
					:dateLeft="`Deleted at: ${new Date(
						course.deleted_at.slice(0, 10)
					).toLocaleDateString()}`"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column align-center">
				<v-btn
					v-if="deletedCourses.length > limit"
					class="admin-primary-button secondary-contrast-background"
					@click="loadMoreCourses"
					>Load More</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import { mapGetters } from "vuex";
import AdminDefaultSubheader from "../../../components/AdminDefaultSubheader.vue";
import { get } from "@/util/requests/get";

export default {
	name: "CoursesDeleted",
	components: { AdminDefaultSubheader },
	props: ["searchText"],
	computed: {
		...mapGetters(["deletedCourses"]),
		displayedCourses() {
			return this.deletedCourses.filter(el =>
				String(el.title).includes(this.searchText)
			);
		}
	},

	data() {
		return {
			limit: 2
		};
	},
	methods: {
		loadMoreCourses() {
			if (!!this.deletedCourses === false) return;
			this.limit = this.deletedCourses.length;
		}
	}
};
</script>

<style></style>
