var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[_c('table',{staticClass:"table table-striped brand draft-list"},[_vm._m(0),_c('tbody',_vm._l((_vm.displayedCourses.slice(0, _vm.limit)),function(course){return _c('RowData',{key:course.id,attrs:{"image":course.images,"name":course.title,"td1Class":`text-center`,"dateLeft":`${new Date(
					course.created_at.slice(0, 10)
				).toLocaleDateString()} <br><small>Last Edited: ${Math.ceil(
					Number(new Date() - new Date(course.updated_at)) /
						1000 /
						60 /
						60 /
						24
				)} days ago</small>`,"buttons":[
					{ title: 'Quiz', action: () => _vm.goEditQuiz(course.id) },
					{
						title: 'Analytics',
						action: () => _vm.showAnalytics(course.id)
					},
					{
						title: 'Delete',
						action: () => _vm.deleteCourse(course.id)
					},
					{
						title: 'Duplicate',
						action: () => _vm.duplicateCourse(course)
					},
					{
						title: 'Edit',
						action: () => _vm.editCourse(course.id)
					}
				]}})}),1)]),(_vm.displayedCourses.length > _vm.limit)?_c('v-btn',{staticClass:"admin-primary-button secondary-contrast-background",attrs:{"loading":_vm.isLoading},on:{"click":_vm.loadMoreCourses}},[_vm._v("Load More")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-2"}),_c('th',{staticClass:"col-3"},[_vm._v("Course Title")]),_c('th',{staticClass:"col-2 text-center"},[_vm._v("Date Created")]),_c('th',{staticClass:"col-6"})])])
}]

export { render, staticRenderFns }