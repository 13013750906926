<template>
	<div class="container">
		<div class="row header">
			<div class="col">
				<h3>Courses</h3>
			</div>
			<div class="col text-right">
				<v-btn
					class="admin-primary-button primary-contrast-background shadow-none"
					@click="goTo('NewCourse')"
					>New Course</v-btn
				>
			</div>
			<div class="col-12">
				<admin-default-description>Start creating your course content by using the course editor. Add videos, images and knowledge check questions to engage with your learners.</admin-default-description>
			</div>
		</div>

		<div class="brand-tabs">
			<v-tabs
				v-model="currentItem"
				ligth
				background-color="transparent"
				:slider-color="$store.getters.theme.mainAccentColor"
				slider-size="8"
				color="#000"
				class="brand-tabs-container"
				:loading="isLoading"
			>
				<v-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:href="`#${tab.component}`"
					class="organization-settings-tab-button primary-text"
					@click="currentItem = tab.component"
				>
					{{ tab.title }}
				</v-tab>
			</v-tabs>

			<div class="row my-4">
				<div class="col">
					SORT
				</div>
				<div class="col">
					<input type="text"
						v-model="searchText"
						class="form-control w-100 search-brand shadow-none border-0 py-1"
						placeholder="SEARCH"
						:loading="isLoading">
					<form-message v-if="message">{{ message }}</form-message>
				</div>
			</div>

			<v-tabs-items
				v-model="currentItem"
				:loading="isLoading"
			>
				<v-tab-item
					v-for="tab in tabs"
					:key="tab.title"
					:value="tab.component"
					class="organization-settings-tab"
					:loading="isLoading"
				>
					<keep-alive>
						<component
							:is="tab.component"
							:message="message"
							:searchText="searchText"
							@setMessage="setMessage"
						/>
					</keep-alive>
				</v-tab-item>
			</v-tabs-items>
		</div>


	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-header>Courses</admin-default-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<admin-default-description
					>Start creating your course content by using the course
					editor. Add videos, images and knowledge check questions to
					engage with your learners.</admin-default-description
				></v-col
			>
		</v-row>

		<v-text-field
			v-model="searchText"
			class="search-course"
			placeholder="Start typing to Search"
			filled
			:loading="isLoading"
		></v-text-field>
		<form-message v-if="message">{{ message }}</form-message>
		<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="organization-settings-tab-buttons-container"
			:loading="isLoading"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<v-tabs-items
			v-model="currentItem"
			class="organization-settings-tabs-container"
			:loading="isLoading"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
				:loading="isLoading"
			>
				<keep-alive>
					<component
						:is="tab.component"
						:message="message"
						:searchText="searchText"
						@setMessage="setMessage"
					/>
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
	</v-container> -->
</template>

<script>
import CoursesDrafts from "./CoursesDrafts.vue";
import CoursesPublished from "./CoursesPublished.vue";
import CoursesDeleted from "./CoursesDeleted.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";
import { mapGetters } from "vuex";
import FormMessage from "@/components/FormMessage.vue";

export default {
	name: "Courses",
	components: {
		CoursesDrafts,
		CoursesPublished,
		CoursesDeleted,
		AdminDefaultHeader,
		AdminDefaultDescription,
		FormMessage
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {
			currentItem: CoursesDrafts,
			message: "",
			searchText: "",
			tabs: [
				{
					title: "Drafts",
					value: "drafts",
					component: "CoursesDrafts"
				},
				{
					title: "Published",
					value: "published",
					component: "CoursesPublished"
				},
				{
					title: "Deleted",
					value: "deleted",
					component: "CoursesDeleted"
				}
			]
		};
	},

	methods: {
		async setInitTab() {
			if (this.$route.query.tab) {
				const tab = this.tabs.find(
					el => el.value === this.$route.query.tab
				);
				this.currentItem = tab.component;
			}
		},
		goTo(name, query) {
			this.$router.push({ name: name, query: query });
		},
		setMessage(val) {
			this.message = val;
		}
	},
	async created() {
		await this.$store.dispatch("setIsLoading", true);
		await this.setInitTab();
		await this.$store.dispatch("getCourses");
		await this.$store.dispatch("setIsLoading", false);
	}
};
</script>

<style></style>
